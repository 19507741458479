import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  color: inherit;
  font-size: 14px;
  background-color: ${props =>
    props.desktopBg ? theme.colors.abdGreenHover : theme.colors.abdGreen};
  border-radius: 50%;
  height: 17px;
  width: 17px;
  position: absolute;
  top: ${props => props.positions.top};
  right: ${props => props.positions.right};
  display: flex;
  align-items: center;
  justify-content: center;

  ${theme.below.lg} {
    background-color: ${theme.colors.abdGreen};
  }
`;

const Badge = ({
  text,
  positions = { top: '10px', right: '12px' },
  desktopBg = false
}) => {
  return (
    <Wrapper positions={positions} desktopBg={desktopBg}>
      <div>{text}</div>
    </Wrapper>
  );
};

export default Badge;
