import { useIntl } from '@jetshop/intl';

const useTranslatableCustomFieldTitles = customFields => {
  const t = useIntl();
  return (
    customFields?.map(field => {
      switch (field.key) {
        case 'chassieNumber':
          return { ...field, translateableTitle: t('Chassis Number') };
        case 'aisManufacturer':
          return { ...field, translateableTitle: t('Manufacturer') };
        case 'bodyStyle':
          return { ...field, translateableTitle: t('Body Style') };
        case 'color':
          return { ...field, translateableTitle: t('Color') };
        case 'countryCode':
          return { ...field, translateableTitle: t('Country Code') };
        case 'engineCC':
          return { ...field, translateableTitle: t('Engine') };
        case 'fuel':
          return { ...field, translateableTitle: t('Fuel') };
        case 'gearBox':
          return { ...field, translateableTitle: t('Gearbox') };
        case 'mileageKm':
          return { ...field, translateableTitle: t('Mileage Km') };
        case 'model':
          return { ...field, translateableTitle: t('Model') };
        case 'modelYear':
          return { ...field, translateableTitle: t('Model year') };
        case 'noOfDoors':
          return { ...field, translateableTitle: t('Number of doors') };
        case 'note':
          return { ...field, translateableTitle: t('Note') };
        case 'engineCode':
          return { ...field, translateableTitle: t('Engine Code') };
        case 'yearNo':
          return { ...field, translateableTitle: t('Year Number') };
        case 'warranty':
          return { ...field, translateableTitle: t('Warranty') };
        case 'visualArtNo':
          return { ...field, translateableTitle: t('Visual Article Number') };
        case 'vatNumber':
          return { ...field, translateableTitle: t('VAT Number') };
        case 'upholstery':
          return { ...field, translateableTitle: t('Upholstery') };
        case 'qualityRemark':
          return { ...field, translateableTitle: t('Quality Remark') };
        case 'quality':
          return { ...field, translateableTitle: t('Quality') };
        case 'productNumber':
          return { ...field, translateableTitle: t('Product Number') };
        case 'partPosition':
          return { ...field, translateableTitle: t('Part Position') };
        case 'partManufacturer':
          return { ...field, translateableTitle: t('Part Manufacturer') };
        case 'partCodeName':
          return { ...field, translateableTitle: t('Part Code Name') };
        case 'originalNumber':
          return { ...field, translateableTitle: t('Original Number') };
        case 'manufacturingYearAndMonth':
          return {
            ...field,
            translateableTitle: t('Manufacturing Year and Month')
          };
        case 'colorCode':
          return { ...field, translateableTitle: t('Color Code') };
        case 'artNr':
          return { ...field, translateableTitle: t('Article Number') };

        default:
          return field;
      }
    }) || []
  );
};

export default useTranslatableCustomFieldTitles;
