import React from 'react';
import CategoryMenuContainer from './CategoryMenuContainer';
import { styled } from 'linaria/react';

const Nav = styled('nav')`
  button {
    background: none;
    color: #666;
  }

  .menu-container {
    margin-top: 0;
  }
`;

export function CategoryMenu({ categories }) {
  return (
    <Nav>
      <div key={'menuContainerPosed'} className="menu-container">
        <CategoryMenuContainer queryData={categories} />
      </div>
    </Nav>
  );
}
