import { styled } from 'linaria/react';
import { css } from 'linaria';
import MaxWidth from '../MaxWidth';
const CampaignBarWrapper = styled('div')`
  background: #80bc00;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CampaignBarContent = styled(MaxWidth)`
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const CampaignText = styled('p')`
  color: black;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
`;

const linkStyles = css`
  margin-left: 16px;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 700;
  color: black;
`;

const style = {
  CampaignBarWrapper,
  CampaignBarContent,
  CampaignText,
  linkStyles
};

export default style;
