import React from 'react';
import { Link } from 'react-router-dom';
import Styled from './Style';
import MaxWidth from '../MaxWidth';
import { styled } from 'linaria/react';
import useCleanProps from '../../../hooks/useCleanProps';

const MaxWidthWrapper = styled(MaxWidth)``;

const CampaignLink = ({ externalLinkUrl, internalLinkUrl, linkLabel }) => {
  if (externalLinkUrl) {
    return (
      <a className={Styled.linkStyles} href={externalLinkUrl}>
        {linkLabel}
      </a>
    );
  }
  if (internalLinkUrl) {
    return (
      <Link className={Styled.linkStyles} to={internalLinkUrl}>
        {linkLabel}
      </Link>
    );
  }
};

const CampaignBar = props => {
  const { externalLinkUrl, internalLinkUrl, linkLabel, text } = useCleanProps(
    props
  );
  if (!text) return null;

  return (
    <Styled.CampaignBarWrapper>
      <MaxWidthWrapper>
        <Styled.CampaignBarContent>
          {text && <Styled.CampaignText>{text}</Styled.CampaignText>}
          {linkLabel && (externalLinkUrl || internalLinkUrl) && (
            <CampaignLink
              externalLinkUrl={externalLinkUrl}
              internalLinkUrl={internalLinkUrl}
              linkLabel={linkLabel}
            />
          )}
        </Styled.CampaignBarContent>
      </MaxWidthWrapper>
    </Styled.CampaignBarWrapper>
  );
};

export default CampaignBar;
