import { css } from 'linaria';

import SSPWoff from './source-sans-pro-v11-latin-regular.woff';
import SSPWoff2 from './source-sans-pro-v11-latin-regular.woff2';

import SSPWoffBold from './source-sans-pro-v11-latin-700.woff';
import SSPWoff2Bold from './source-sans-pro-v11-latin-700.woff2';

import SSPWoffSemiBold from './source-sans-pro-v13-latin-600.woff';
import SSPWoff2SemiBold from './source-sans-pro-v13-latin-600.woff2';

import RubikRegularWoff from './rubik-v14-latin-regular.woff';
import RubikRegularWoff2 from './rubik-v14-latin-regular.woff2';
import RubikRegularTTF from './rubik-v14-latin-regular.ttf';

import RubikMediumWoff from './rubik-v14-latin-500.woff';
import RubikMediumWoff2 from './rubik-v14-latin-500.woff2';
import RubikMediumTTF from './rubik-v14-latin-500.ttf';

import RubikSemiBoldWoff from './rubik-v14-latin-600.woff';
import RubikSemiBoldWoff2 from './rubik-v14-latin-600.woff2';
import RubikSemiBoldTTF from './rubik-v14-latin-600.ttf';

import RubikBoldWoff from './rubik-v14-latin-700.woff';
import RubikBoldWoff2 from './rubik-v14-latin-700.woff2';
import RubikBoldTTF from './rubik-v14-latin-700.ttf';

import BarlowWoff from './barlow-v12-latin-regular.woff';
import BarlowWoff2 from './barlow-v12-latin-regular.woff2';

import BarlowMediumWoff from './barlow-v12-latin-500.woff';
import BarlowMediumWoff2 from './barlow-v12-latin-500.woff2';

import BarlowSemiBoldWoff from './barlow-v12-latin-600.woff2';
import BarlowSemiBoldWoff2 from './barlow-v12-latin-600.woff';

import BarlowBoldWoff from './barlow-v12-latin-700.woff';
import BarlowBoldWoff2 from './barlow-v12-latin-700.woff2';

import BarlowBoldItalicWoff from './barlow-v12-latin-700italic.woff';
import BarlowBoldItalicWoff2 from './barlow-v12-latin-700italic.woff2';

import BarlowExtraBoldWoff from './barlow-v12-latin-800.woff';
import BarlowExtraBoldWoff2 from './barlow-v12-latin-800.woff2';

import BarlowExtraBoldItalicWoff from './barlow-v12-latin-800italic.woff';
import BarlowExtraBoldItalicWoff2 from './barlow-v12-latin-800italic.woff2';

// https://fonts.google.com/specimen/Barlow?query=Barlow

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* barlow-regular - latin */
      @font-face {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Barlow Regular'), url(${BarlowWoff2}) format('woff2'),
          url(${BarlowWoff}) format('woff');
      }
      /* barlow-500 - latin */
      @font-face {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        src: local('Barlow Medium'), url(${BarlowMediumWoff2}) format('woff2'),
          url(${BarlowMediumWoff}) format('woff');
      }
      /* barlow-600 - latin */
      @font-face {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: local('Barlow Semi-Bold'),
          url(${BarlowSemiBoldWoff2}) format('woff2'),
          url(${BarlowSemiBoldWoff}) format('woff');
      }
      /* barlow-700 - latin */
      @font-face {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Barlow Bold'), url(${BarlowBoldWoff2}) format('woff2'),
          url(${BarlowBoldWoff}) format('woff');
      }
      /* barlow-700italic - latin */
      @font-face {
        font-family: 'Barlow';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: local('Barlow Bold Italic'),
          url(${BarlowBoldItalicWoff2}) format('woff2'),
          url(${BarlowBoldItalicWoff}) format('woff');
      }
      /* barlow-800 - latin */
      @font-face {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 800;
        font-display: swap;
        src: local('Barlow Extra-Bold'),
          url(${BarlowExtraBoldWoff2}) format('woff2'),
          url(${BarlowExtraBoldWoff}) format('woff');
      }
      /* barlow-800italic - latin */
      @font-face {
        font-family: 'Barlow';
        font-style: italic;
        font-weight: 800;
        font-display: swap;
        src: local('Barlow Extra-Bold Italic'),
          url(${BarlowExtraBoldItalicWoff2}) format('woff2'),
          url(${BarlowExtraBoldItalicWoff}) format('woff');
      }

      /* Regular font 400 */
      @font-face {
        font-family: 'Rubik';
        src: local('Rubik Regular'), url(${RubikRegularWoff2}) format('woff2'),
          url(${RubikRegularWoff}) format('woff'),
          url(${RubikRegularTTF}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      /* Medium font 500 */
      @font-face {
        font-family: 'Rubik';
        src: local('Rubik Medium'), url(${RubikMediumWoff2}) format('woff2'),
          url(${RubikMediumWoff}) format('woff'),
          url(${RubikMediumTTF}) format('truetype');
        font-style: normal;
        font-weight: 500;
        font-display: swap;
      }
      /* Semi-bold font 600 */
      @font-face {
        font-family: 'Rubik';
        src: local('Rubik Semi-bold'),
          url(${RubikSemiBoldWoff2}) format('woff2'),
          url(${RubikSemiBoldWoff}) format('woff'),
          url(${RubikSemiBoldTTF}) format('truetype');
        font-style: normal;
        font-weight: 600;
        font-display: swap;
      }
      /* Bold font 700 */
      @font-face {
        font-family: 'Rubik';
        src: local('Rubik Bold'), url(${RubikBoldWoff2}) format('woff2'),
          url(${RubikBoldWoff}) format('woff'),
          url(${RubikBoldTTF}) format('truetype');
        font-style: normal;
        font-weight: 700;
        font-display: swap;
      }

      /* Regular font */
      @font-face {
        font-family: 'Source Sans Pro';
        src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
          url(${SSPWoff2}) format('woff2'), url(${SSPWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      /* Bold font */
      @font-face {
        font-family: 'Source Sans Pro';
        src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
          url(${SSPWoff2Bold}) format('woff2'),
          url(${SSPWoffBold}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Source Sans Pro';
        src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
          url(${SSPWoff2SemiBold}) format('woff2'),
          url(${SSPWoffSemiBold}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }
      &.source-sans-pro-ready body {
        font-family: 'Source Sans Pro', sans-serif;
        line-height: 1;
        letter-spacing: 0;
        word-spacing: 0;
        font-weight: normal;
      }
    }
  `;
}
