import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { ReactComponent as CheckBox } from '../../../assets/svg/Check.svg';
import MaxWidth from '../MaxWidth';
import useCleanProps from '../../../hooks/useCleanProps';

const InformationUspWrapper = styled('div')`
  background: #f3f3f3;

  ${theme.below.lg} {
    background: #f5f5f5;
  }

  ${theme.below.md} {
    display: none;
  }
`;

const MaxWidthWrapper = styled(MaxWidth)``;

const ContentWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  padding: 0.8rem 0;
`;

const InnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const InformationTextContent = styled('p')`
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.05em;
`;

const InformationUsp = props => {
  const { textItem1, textItem2, textItem3, textItem4 } = useCleanProps(props);

  return (
    <InformationUspWrapper>
      <MaxWidthWrapper>
        <ContentWrapper>
          <InnerWrapper>
            <CheckBox />
            <InformationTextContent>{textItem1}</InformationTextContent>
          </InnerWrapper>
          <InnerWrapper>
            <CheckBox />
            <InformationTextContent>{textItem2}</InformationTextContent>
          </InnerWrapper>
          <InnerWrapper>
            <CheckBox />
            <InformationTextContent>{textItem3}</InformationTextContent>
          </InnerWrapper>
          <InnerWrapper>
            <CheckBox />
            <InformationTextContent>{textItem4}</InformationTextContent>
          </InnerWrapper>
        </ContentWrapper>
      </MaxWidthWrapper>
    </InformationUspWrapper>
  );
};

export default InformationUsp;
