import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import { useIntl } from '@jetshop/intl';
import SubMenuWrapper from './SubMenuWrapper';
import useOutsideClick from '../../../../hooks/useOutsideClick'; // Adjust the path as necessary
import SubMenuWrapperAlt from './SubMenuWrapperAlt';

const Wrapper = styled('div')`
  height: 100%;
  font-family: 'Barlow';

  .level-1-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    justify-content: center;
    cursor: pointer;
  }
`;

const MainMenuItem = styled('li')`
  a {
    font-weight: 700;
    font-size: 1rem;
    line-height: 14px;
    position: relative;
    color: #ffffff;
    text-decoration: none;
    &:hover {
      color: #ffffff !important;
      text-decoration: underline;
    }
  }
`;

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const CategoryMenu = ({
  queryData,
  activeCategories,
  clearActiveCategories,
  setActiveCategory
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (!menuOpen) {
      clearActiveCategories();
    }
  }, [menuOpen]);

  const ref = useOutsideClick(() => {
    setMenuOpen(false);
  });

  const toggleMenuOpen = (e, category) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      setActiveCategory(category);
    }
  };

  const closeSubMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Wrapper ref={ref}>
      <ul className={'level-1-list'}>
        {queryData &&
          queryData.map(category => (
            <CategoryMenuItem
              key={category.id}
              category={category}
              toggleMenuOpen={toggleMenuOpen}
            />
          ))}
      </ul>

      {activeCategories.length > 0 &&
        activeCategories[0].subcategories.length > 0 &&
        (activeCategories[0].id === 2041 ? (
          <SubMenuWrapperAlt
            pose={menuOpen ? 'open' : 'closed'}
            activeCategory={activeCategories[0]}
            closeNav={closeSubMenu}
          />
        ) : (
          <SubMenuWrapper
            pose={menuOpen ? 'open' : 'closed'}
            activeCategory={activeCategories[0]}
            closeNav={closeSubMenu}
          />
        ))}
    </Wrapper>
  );
};

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <Container>
      <UIMenuContainer>
        {props => (
          <CategoryMenu
            activeCategories={props.activeCategories}
            clearActiveCategories={props.clearActiveCategories}
            setActiveCategory={props.setActiveCategory}
            queryData={queryData}
          />
        )}
      </UIMenuContainer>
    </Container>
  );
};

const CategoryMenuItem = ({ category, toggleMenuOpen }) => {
  const t = useIntl();
  if (!category) return null;
  let displayName = category.id === 2 ? t('Find categories') : category?.name;

  return (
    <MainMenuItem>
      <CategoryLink
        onClick={e => toggleMenuOpen(e, category)}
        category={category}
      >
        {displayName}
      </CategoryLink>
    </MainMenuItem>
  );
};

export default CategoryMenuContainer;
