import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { ReactComponent as EmailIcon } from '../../../assets/svg/email.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/svg/phone.svg';

import SocialLinks from './SocialLinks';

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
`;

const Text = styled('a')`
  font-size: 16px;
  height: 1.625rem;
  line-height: 2rem;
  width: 11rem;
  color: #ffffff;

  ${theme.below.sm} {
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: 0.1px;
  }
`;

const CenterSpace = styled('div')`
  width: 0.25rem;
`;

export const Email = ({ email }) => {
  const EmailWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
  `;

  const StyledEmailIcon = styled(EmailIcon)`
    width: 1rem;
    height: 0.8125rem;
  `;

  return (
    <EmailWrapper>
      <IconWrapper>
        <StyledEmailIcon />
      </IconWrapper>
      <CenterSpace />
      <Text href={`mailto:${email}`}>{email}</Text>
    </EmailWrapper>
  );
};

export const Phone = ({ phone }) => {
  const PhoneWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;

    ${theme.below.xl} {
      margin-bottom: 2rem;
    }
    ${theme.above.xl} {
      margin-bottom: 0.875rem;
    }
  `;

  const StyledPhoneIcon = styled(PhoneIcon)`
    width: 1.1875rem;
    height: 1.25rem;
  `;

  return (
    <PhoneWrapper>
      <IconWrapper>
        <StyledPhoneIcon />
      </IconWrapper>
      <CenterSpace />
      <Text href={`tel:${phone}`}>{phone}</Text>
    </PhoneWrapper>
  );
};

export const SocialMedia = ({ socialMediaText, faceBookLinkUrl }) => {
  const SocialMediaWrapper = styled('div')`
    display: flex;
    ${theme.below.xl} {
      flex-direction: row;
      align-items: center;
    }
    ${theme.above.xl} {
      flex-direction: column;
    }

    p {
      margin-right: 1rem;
    }
  `;

  return (
    <SocialMediaWrapper>
      <SocialLinks
        socialMediaText={socialMediaText}
        faceBookLinkUrl={faceBookLinkUrl}
      />
    </SocialMediaWrapper>
  );
};
