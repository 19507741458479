import { useIntl } from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { cx } from 'linaria';
import React from 'react';
import ProductCardPriceSticker from '../../ProductPage/ProductCardPriceSticker';
import ProductCardBadge from '../../ProductPage/ProductCardBadge';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/svg/QuestionMark.svg';
import { ModalTrigger } from '@jetshop/ui/Modal/ModalTrigger';
import Styled from './Style';
import QualityWarrantyInfo from '../../ProductPage/QualityWarantyInfo';
import { useFitsCarModel } from '../../ProductPage/useFitsCarModel';

export const SpecificationDetail = ({ translateableTitle, value, size }) => {
  if (!value || !translateableTitle) return null;
  return (
    <Styled.SpecificationDetailContainer size={size}>
      <Styled.SpecificationTitle>
        {translateableTitle}
        {size !== 'large' && ':'}
      </Styled.SpecificationTitle>
      <Styled.SpecificationValue size={size}>{value}</Styled.SpecificationValue>

      {size === 'large' && (
        <ModalTrigger target={QualityWarrantyInfo}>
          {({ showTarget }) => (
            <Styled.ModalButton onClick={showTarget}>
              <QuestionMarkIcon style={{ height: '18px', width: '18px' }} />
            </Styled.ModalButton>
          )}
        </ModalTrigger>
      )}
    </Styled.SpecificationDetailContainer>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'div',
  loadImageEagerly = false,
  ...linkProps
}) {
  const {
    imageUrls,
    quality,
    model,
    modelYear,
    mileageKm,
    originalNumber,
    visualArtNo,
    fitsCar
  } = useCustomFields(product);
  const images = imageUrls?.listValues;
  const hasImages = images && images.length > 0;
  const Tag = as;
  let badges = [...(product.badges || [])];
  const t = useIntl();

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  const hasDiscount = product.previousPrice?.incVat !== product.price?.incVat;

  const carModel = useFitsCarModel(product);

  if (!fitsCar) return null;

  return (
    <Tag
      className={cx('product-card', Styled.wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink product={product} {...linkProps}>
        <div>
          <Styled.TopBar>
            {hasDiscount && (
              <ProductCardBadge
                price={product.price}
                previousPrice={product.previousPrice}
              />
            )}
            <div>
              <SpecificationDetail size="large" {...quality} />
            </div>
            {fitsCar && (
              <p>
                {t('OriginalPart')} {fitsCar?.items[0]?.value}
              </p>
            )}
          </Styled.TopBar>

          <Styled.ImageWrapper>
            {hasImages ? (
              <Image
                sizes={imageSizes}
                aspect={imageAspect}
                alt={''}
                src={images[0]}
                badges={<Badges badges={badges} />}
                critical={loadImageEagerly}
                fillAvailableSpace={true}
              ></Image>
            ) : (
              <Image src={transparentDataImg} />
            )}
          </Styled.ImageWrapper>
          <Styled.ProductTitle>{product.name}</Styled.ProductTitle>
          {visualArtNo ? (
            <SpecificationDetail {...visualArtNo} />
          ) : (
            <p>
              Visuellt nr: <span style={{ fontWeight: '700' }}>-</span>
            </p>
          )}
          <SpecificationDetail {...originalNumber} />
          <SpecificationDetail {...model} value={carModel || model?.value} />
          <SpecificationDetail {...modelYear} />
          <SpecificationDetail {...mileageKm} />
        </div>
        <div>
          <div className="price-package-wrapper">
            <ProductCardPriceSticker
              price={product.price}
              previousPrice={product.previousPrice}
            />
          </div>
        </div>
      </ProductLink>
    </Tag>
  );
}
