import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { theme } from '../../Theme';
import MaxWidth from '../MaxWidth';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { LogoSearchBar } from './LogoSearchBar';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import TopNav from './TopNav';
import { Notifications } from '../Notifications';
import CampaignBar from './CampaignBar';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import HeaderQuery from '../Header/HeaderQuery.gql';
import ContactBarUsp from './ContactBarUsp';
import InformationUsp from './InformationUsp';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import { css } from 'linaria';
import MobileMenu from './MobileMenu';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import { ReactComponent as User } from '../../../svg/User.svg';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import { NAVIGATIONHEIGHT } from '../../../constants/height';

const MyPagesLink = styled(Link)`
  display: flex;
  align-items: center;
  svg {
    margin: 4px;
    margin-bottom: 0;
  }
`;

const Container = styled('header')`
  background-color: ${theme.colors.abdDarkGrey};
  position: relative;
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;
    color: white;

    &:hover {
      text-decoration: underline;
    }
    ${theme.below.lg} {
      color: black;
    }
  }

  ${theme.below.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
  }

  .header-button {
    text-decoration: none;
    color: #ffffff;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;

    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
`;

const Separator = styled('div')`
  width: 2px;
  background-color: #ffffff;
  height: 32px;
  top: 0;
  margin-left: 10px;
  margin-right: 10px;

  ${theme.below.md} {
    display: none;
  }
`;

const HeaderContainer = styled('div')`
  height: ${NAVIGATIONHEIGHT ?? '72px'};
  position: relative;
  z-index: 99;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Barlow';
`;

const HeaderItemsContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: flex-start;
  svg {
    height: 18px;
    width: 18px;
  }
  :focus,
  :active,
  :hover {
    outline: none;
    opacity: 0.8;
  }
  label {
    margin-top: 3px;
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

const innerContainer = css`
  margin-right: 20px;

  ${theme.below.lg} {
    display: none;
  }
`;

const separator = css`
  ${theme.below.lg} {
    display: none;
  }
`;

function MobileHeader() {
  return (
    <>
      <DrawerTrigger preventOverflow={true} id="menu-drawer">
        {drawer => (
          <MenuButton
            onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
          >
            <div className="header-button">
              <Menu />
              <div className="menuTextStyles">Meny</div>
            </div>
          </MenuButton>
        )}
      </DrawerTrigger>
    </>
  );
}

function TopHeader({ categories, data, headerData }) {
  const [searchOpen, setSearchOpen] = React.useState(false);
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  const logo = headerData.find(item => item.type === 'Logo');

  return (
    <HeaderContainer>
      <MaxWidth>
        <HeaderItemsContainer>
          <Below breakpoint="lg">
            <>
              <MobileHeader />
              <MobileMenu data={data} headerData={headerData} />
            </>
          </Below>
          <LogoSearchBar
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
            logo={logo}
          />
          <Above breakpoint="lg">
            <>
              <TopNav searchOpen={searchOpen} right>
                <CategoryMenu categories={categories} />
                <Separator className={separator} />
                <MyPagesLink
                  className="header-button-second"
                  to={routes.myPages.path}
                >
                  <User fill={'white'} />
                  <span>{loggedIn ? t('My Pages') : t('Log in')}</span>
                </MyPagesLink>
                <div className={innerContainer}>
                  <FavouriteCount />
                </div>
                <CartButton className="header-button" />
              </TopNav>
            </>
          </Above>
          <Below breakpoint="lg">
            <CartButton className="header-button" />
          </Below>
          <CartFlyout />
        </HeaderItemsContainer>
      </MaxWidth>
    </HeaderContainer>
  );
}

export default function Header() {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });

  const filteredCategories = result?.data?.categories?.filter(category => {
    return category.id === 2 || category.id === 2041;
  });

  const categoryId = process.env.REACT_APP_HEADER_CATEGORY_ID;

  const { data } = useQuery(HeaderQuery, {
    variables: { headerCategoryId: Number(categoryId) }
  });

  const topBannerComponents = {
    CAMPAIGNBAR: CampaignBar
  };

  const contactUspComponents = {
    CONTACTBARUSP: ContactBarUsp
  };

  const informationUspComponents = {
    INFORMATIONUSP: InformationUsp
  };

  return (
    <>
      <Notifications />
      <ContentRenderer
        items={data?.category?.data?.items ?? []}
        components={topBannerComponents}
      />
      <Above breakpoint="sm">
        <ContentRenderer
          items={data?.category?.data?.items ?? []}
          components={contactUspComponents}
        />
      </Above>
      <ChannelBanner />
      <Container>
        <TopHeader
          categories={filteredCategories}
          data={result?.data}
          headerData={data?.category?.data?.items ?? []}
        />
      </Container>
      <ContentRenderer
        items={data?.category?.data?.items ?? []}
        components={informationUspComponents}
      />
    </>
  );
}
