import React from 'react';
import { SearchBar } from './SearchBar';
import { Logo } from './Logo';
import { styled } from 'linaria/react';

const LogoSearchBarWrapper = styled('div')`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
`;
function LogoSearchBar({ searchOpen, setSearchOpen, logo }) {
  const logoType = logo?.properties.find(item => item?.name === 'logo')?.value
    ?.value;
  return (
    <LogoSearchBarWrapper>
      {searchOpen ? (
        <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
      ) : (
        <Logo logo={logoType} />
      )}
    </LogoSearchBarWrapper>
  );
}

export { LogoSearchBar };
