import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import FooterLogoSocialDescription from './FooterLogoSocialDescription';
import USPFooter from './USPFooter';
import FooterQuery from '../Footer/FooterQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import FooterNavLinks from './FooterNavLinks';
import MaxWidth from '../MaxWidth';

const FooterContainer = styled('div')`
  position: relative;
  padding: 3rem 0;
  overflow: hidden;
  background-color: #1a202d;
  z-index: 2;

  ${theme.below.sm} {
    display: none;
  }
`;

const MaxWidthWrapper = styled(MaxWidth)``;

const ResponsiveWrapper = styled('div')`
  display: flex;
  height: auto;
  max-width: 85%;

  p {
    padding: 0.5rem 0;
  }

  ${theme.below.lg} {
    flex-direction: column;
  }

  ${theme.above.xl} {
    justify-content: space-between;
  }
`;

const Footer = () => {
  const footerCategoryId = process.env.REACT_APP_FOOTER_CATEGORY_ID;

  const { data } = useQuery(FooterQuery, {
    variables: { footerCategoryId: Number(footerCategoryId) }
  });

  const items = data?.category?.data?.items ?? [];

  const footerComponents = {
    FOOTERNAVIGATIONLINKS: FooterNavLinks,
    FOOTERNAVIGATIONLINKSCOMPONENT: 'footerNavigationLinks'
  };

  const footerBlockItems = items.filter(item => item.type === 'FooterBlock');

  return (
    <>
      <FooterContainer>
        <MaxWidthWrapper>
          <ResponsiveWrapper>
            {footerBlockItems.length > 0 && (
              <FooterLogoSocialDescription {...footerBlockItems} />
            )}
            <ContentRenderer items={items} components={footerComponents} />
          </ResponsiveWrapper>
        </MaxWidthWrapper>
      </FooterContainer>
      <USPFooter />
    </>
  );
};

export default Footer;
