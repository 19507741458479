import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { ReactComponent as KlarnaLogo } from '../../../assets/svg/klarna.svg';
import { ReactComponent as CertEhandelLogo } from '../../../assets/svg/cert-ehandel.svg';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import MaxWidth from '../MaxWidth';

const Wrapper = styled('div')`
  background: #f6f6f6;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  padding: 10px 0;

  ${theme.below.sm} {
    display: none;
  }
`;

const MaxWidthWrapper = styled(MaxWidth)``;

const ContentWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${theme.below.sm} {
    flex-direction: column;
  }
`;

const CopyRightText = styled('p')`
  font-size: 0.75rem;
  font-weight: 500;
  color: #9a9a9a;
`;

const PaymentOptionsText = styled('p')`
  font-size: 0.875rem;
  font-weight: 600;
  color: black;
  margin-left: 84px;
  display: flex;

  ${theme.below.md} {
    margin-left: 36px;
  }

  ${theme.below.sm} {
    font-size: 0.875rem;
    letter-spacing: 3%;
  }
`;

const LogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const USPFooter = () => {
  return (
    <Wrapper>
      <MaxWidthWrapper>
        <ContentWrapper>
          <Above breakpoint="sm">
            <CopyRightText>
              Alla rättigheter förbehållna, Allabildelar © 2024
            </CopyRightText>
          </Above>
          <Below breakpoint="sm">
            <PaymentOptionsText>
              Handla och betala tryggt hos oss:
            </PaymentOptionsText>
          </Below>
          <LogoWrapper>
            <KlarnaLogo />
            <CertEhandelLogo />
          </LogoWrapper>
        </ContentWrapper>
      </MaxWidthWrapper>
    </Wrapper>
  );
};

export default USPFooter;
