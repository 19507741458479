import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { Currency } from '@jetshop/ui/Price/Currency';

const ProductPriceWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

const CurrentPrice = styled('p')`
  color: ${theme.colors.abdGreen};
  font-family: 'Barlow';
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
`;

const NewPrice = styled('p')`
  color: ${theme.colors.red};
  font-family: 'Barlow';
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
`;

const PreviousPrice = styled('p')`
  color: #646464;
  font-family: 'Barlow';
  font-size: 14px;
  font-weight: 500;
  text-decoration-line: line-through;
  line-height: normal;
`;

const ProductCardPriceSticker = ({ price, previousPrice }) => {
  const hasDiscount = previousPrice?.incVat !== price?.incVat;

  return (
    <ProductPriceWrapper>
      {hasDiscount ? (
        <>
          <PreviousPrice>
            <Currency value={previousPrice?.incVat} />
          </PreviousPrice>
          <NewPrice>
            <Currency value={price?.incVat} />
          </NewPrice>
        </>
      ) : (
        <>
          <CurrentPrice>
            <Currency value={price?.incVat} />
          </CurrentPrice>
        </>
      )}
    </ProductPriceWrapper>
  );
};

export default ProductCardPriceSticker;
