import React from 'react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';
import { styled } from 'linaria/react';

const CTALinkStyles = styled('div')`
  background-color: ${theme.colors.abdGreen};
  color: ${theme.colors.white};
  margin-top: 35px;
  width: 200px;
  height: 52px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;

  :link,
  :visited,
  :hover,
  :active {
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  ${theme.below.md} {
    height: 48px;
  }
  ${theme.below.sm} {
    width: 335px;
  }
`;

const CTALink = ({
  children,
  secondary,
  internalLink,
  externalLink,
  plain
}) => {
  if (externalLink) {
    return (
      <a
        href={externalLink}
        className={cx(
          CTALinkStyles,
          secondary && 'secondary',
          plain && 'plain'
        )}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={internalLink}
      className={cx(CTALinkStyles, secondary && 'secondary', plain && 'plain')}
    >
      {children}
    </Link>
  );
};

export default CTALink;
