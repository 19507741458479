import React from 'react';
import useCleanProps from '../../hooks/useCleanProps';

const QualityWarrantyData = props => {
  const { content } = useCleanProps(props);

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default QualityWarrantyData;
