import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { useIntl } from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Link } from 'react-router-dom';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Below } from '@jetshop/ui/Breakpoints';
import CloseButton from '../../ui/CloseButton';
import MobileCategories from './Categories/MobileCategories';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import { ReactComponent as LocationPin } from '../../../svg/LocationPin.svg';
import { ReactComponent as User } from '../../../svg/User.svg';
import { convertPropertiesToObject } from '../../../helpers';
import ContactBarUsp from './ContactBarUsp';

const StyledCloseButton = styled(CloseButton)`
  margin-left: 0;
  margin-right: auto;
`;

const Title = styled('h1')`
  text-align: center;
  position: absolute;
  font-size: 1.125rem;
`;

const NavBarHeader = styled('div')`
  position: relative;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: 3.125rem;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
`;

const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  flex: 0 0 auto;
`;

const MenuListItem = styled.li`
  list-style: none;
  display: block;
  padding: 0.5rem 1rem;
  a,
  a:active {
    display: flex;
    gap: 6px;
    text-decoration: none;
    color: black;
  }
  button {
    background: none;
    color: black;
  }
  border-bottom: ${props => (props.borders ? '1px solid #e7e4e4' : 'none')};
  border-top: ${props => (props.borders ? '1px solid #e7e4e4' : 'none')};
  margin-bottom: ${props => (props.marginBottom ? '1rem' : '0')};
`;

const locationPinStyles = css``;

export default function MobileMenu({ data, headerData }) {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  const t = useIntl();
  const customLinks =
    headerData
      .find(item => item.type === 'HeaderMobileNavigationLinks')
      ?.children?.map(link => convertPropertiesToObject(link.properties)) || [];

  if (!(data && data?.categories)) return null;

  const filteredCategories = data?.categories
    ?.map(category => {
      if (category.id === 2) {
        return { ...category, name: t('Find categories') };
      }
      return category;
    })
    .filter(category => category.id === 2);

  const contactUspComponents = {
    CONTACTBARUSP: ContactBarUsp
  };

  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen}>
          <NavBarHeader>
            <StyledCloseButton onClick={drawer.hideTarget} />
            <Title>{t('Menu')}</Title>
          </NavBarHeader>
          <Scroll>
            <MenuList>
              <MenuListItem borders marginBottom>
                <MobileCategories
                  categories={filteredCategories}
                  closeMenu={drawer.hideTarget}
                />
              </MenuListItem>
              <MenuListItem>
                <Link to="/bil" onClick={drawer.hideTarget}>
                  {t('Show all products')}
                </Link>
              </MenuListItem>
              {customLinks &&
                customLinks.length > 0 &&
                customLinks.map(item => {
                  return (
                    <MenuListItem key={item.linkTitle}>
                      {item.internalLinkUrl ? (
                        <Link
                          to={item.internalLinkUrl}
                          onClick={drawer.hideTarget}
                        >
                          {item.linkTitle}
                        </Link>
                      ) : (
                        <a
                          href={item.externalLinkUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.linkTitle}
                        </a>
                      )}
                    </MenuListItem>
                  );
                })}
              <MenuListItem>
                <Link onClick={drawer.hideTarget} to={routes.myPages.path}>
                  <User fill={'black'} />
                  {loggedIn ? t('My Pages') : t('Log in')}
                </Link>
              </MenuListItem>
              <MenuListItem onClick={drawer.hideTarget}>
                <FavouriteCount color="black" />
              </MenuListItem>
              <MenuListItem>
                <Link to={routes.stores.path} onClick={drawer.hideTarget}>
                  <LocationPin className={locationPinStyles} />
                  {t('Find Store')}
                </Link>
              </MenuListItem>
            </MenuList>
            <Below breakpoint="sm">
              <ContentRenderer
                items={headerData}
                components={contactUspComponents}
              />
            </Below>
          </Scroll>
        </Drawer>
      )}
    </DrawerTarget>
  );
}
