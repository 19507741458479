import { useProductList } from '@jetshop/core/hooks/ProductList';
import { useIntl } from '@jetshop/intl';
import React from 'react';
import { Link } from 'react-router-dom';
import Badge from '../ui/Badge';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import { ReactComponent as SaveIcon } from '../../svg/saveAlt2.svg';

const wrapper = css`
  display: flex;
  align-items: center;
`;

const icons = css`
  position: relative;
`;

const Text = styled('p')`
  font-family: 'Barlow';
  font-size: 1rem;
  color: ${props => props.color};
  padding-left: 8px;
`;

export function FavouriteCount({ className, listId, color = 'white' }) {
  const { count } = useProductList(listId);
  const t = useIntl();

  return (
    <Link to="/favourites" className={className}>
      <div className={wrapper}>
        <div className={icons}>
          <SaveIcon
            style={{ height: '1.3em', width: '1.3em', color: 'white' }}
            fill={color}
          />
          {count > 0 && (
            <Badge text={count} positions={{ top: '-4px', right: '-10px' }} />
          )}
        </div>
        <Text color={color}> {t('My shopping list')}</Text>
      </div>
    </Link>
  );
}
