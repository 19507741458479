import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { Link } from 'react-router-dom';
import { Email, Phone, SocialMedia } from './Contact';
import {
  cleanProps,
  getComponentItems,
  convertPropertiesToObject
} from '../../../helpers';

const AboutUsText = styled('div')`
  white-space: pre-line;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  max-width: 400px;

  ${theme.below.xl} {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 1.875rem;
  }
  ${theme.above.xl} {
    font-size: 0.875rem;
    line-height: 1.225rem;
    margin-bottom: 1.25rem;
  }

  ${theme.below.sm} {
    font-size: 14px;
  }
`;

const UiLink = styled(Link)`
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.625rem;

  ${theme.below.md} {
    display: none;
  }

  ${theme.below.sm} {
    display: none;
  }
`;

const ExternalLink = styled('a')`
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.625rem;
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LogoContactWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-right: 2rem;

  ${theme.below.sm} {
    margin-left: 0;
  }
`;

const DescriptionContentWrapper = styled('div')`
  padding-left: 2rem;

  ${theme.below.md} {
    margin-left: 0;
  }

  ${theme.below.sm} {
    margin-left: 0;
    padding-left: 0;
  }
`;

const FooterLogoSocialDescriptionWrapper = styled('section')`
  display: flex;
  flex-direction: row;

  flex: 1 1 60%;

  ${theme.below.xl} {
    margin-left: 0px;
  }

  ${theme.below.md} {
    width: auto;
  }

  ${theme.below.sm} {
    flex-direction: column;
    width: auto;
  }
`;

const Contact = styled('div')`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  ${theme.below.xl} {
    margin-top: 30px;
  }
`;

const ContactInfoWrapper = styled('div')``;

const Partners = styled('div')`
  margin-top: 1rem;
  margin-bottom: 58.8px;

  h2 {
    color: white;
    font-size: 1.2rem;
  }
`;

const SocialMediaWrapper = styled('div')`
  ${theme.below.lg} {
    margin-top: 40px;
  }
`;

const StyledLogo = styled('img')`
  ${theme.above.xl} {
    width: 10rem;
    margin-bottom: 1rem;
  }
  ${theme.below.xl} {
    width: 13.375rem;
    margin-bottom: 1rem;
  }
`;

const FooterLinksTitle = styled('h2')`
  font-size: 1.2rem;
  line-height: 1.75rem;
  color: #ffffff;

  ${theme.below.sm} {
    font-size: 18px;
  }
`;

const TitleWrapper = styled('div')``;

const GreenLine = styled('div')`
  background: #6cc400;
  width: 21px;
  height: 2px;
  margin-bottom: 10px;

  ${theme.below.sm} {
    width: 40px;
    height: 4px;
  }
`;

const FooterLogoSocialDescription = props => {
  const cleanedProps = Object.values(props)[0];

  const {
    linkLabel,
    linkUrl,
    email,
    phone,
    descriptionTitle,
    descriptionText,
    logo,
    socialMediaText,
    faceBookLinkUrl,
    friendsLinkListHeading
  } = cleanProps(convertPropertiesToObject(cleanedProps.properties));

  const linkComponents = getComponentItems(
    cleanedProps.children,
    'linkComponent'
  );

  return (
    <FooterLogoSocialDescriptionWrapper>
      <LogoContactWrapper>
        <StyledLogo src={logo} alt="logotype" />
        <Contact>
          <ContactInfoWrapper>
            <Email email={email} />
            <Phone phone={phone} />
          </ContactInfoWrapper>
          {linkComponents && linkComponents.length > 0 && (
            <Partners>
              <h2>{friendsLinkListHeading}</h2>
              {linkComponents.map(link => (
                <ExternalLink
                  key={link.linkTitle}
                  aria-label={link.linkTitle}
                  target={'_blank'}
                  rel="noreferrer"
                  href={link.externalLinkUrl}
                >
                  {link.linkTitle}
                </ExternalLink>
              ))}
            </Partners>
          )}
          <SocialMediaWrapper>
            <SocialMedia
              socialMediaText={socialMediaText}
              faceBookLinkUrl={faceBookLinkUrl}
            />
          </SocialMediaWrapper>
        </Contact>
      </LogoContactWrapper>

      <DescriptionContentWrapper>
        {descriptionTitle && (
          <TitleWrapper>
            <FooterLinksTitle>{descriptionTitle}</FooterLinksTitle>
            <GreenLine />
          </TitleWrapper>
        )}
        {descriptionText && <AboutUsText>{descriptionText}</AboutUsText>}
        {linkUrl && <UiLink to={linkUrl}>{linkLabel}</UiLink>}
      </DescriptionContentWrapper>
    </FooterLogoSocialDescriptionWrapper>
  );
};

export default FooterLogoSocialDescription;
