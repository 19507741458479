import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { css } from 'linaria';
import { theme } from '../Theme';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as CrossIcon } from '@jetshop/ui/svg/Cross.svg';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import removeFromCartMutation from './queries/removeFromCart.gql';
import cartQuery from './queries/cartQuery.gql';
import useProductBooking from '../ProductPage/useProductBooking';

const Divider = styled('div')`
  border-top: 0.5px solid lightgray;
  border-bottom: 0.5px solid lightgray;
  margin: 0.5rem 0;
`;

const Wrapper = styled('div')`
  display: flex;
  margin-bottom: 0.5rem;
  margin: 9px 12px 9px 12px;
  > :first-child {
    flex: 1 1 25%;
  }
  > :last-child {
    flex: 1 1 70%;
    padding: 0.5rem;
  }
`;

const InnerWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const ProductName = styled('div')`
  font-size: 0.75rem;
  font-weight: normal;

  ${theme.below.sm} {
    font-size: 0.7rem;
  }

  a {
    color: ${theme.colors.black};
  }
  .attribute {
    display: block;
  }
`;

const ProductDetail = styled('section')`
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
`;

const ItemDetails = styled('div')`
  grid-area: topleft;
`;

const LinePrice = styled(Price)`
  margin-top: 1rem;
  grid-area: bottomright;
  font-weight: 600;
  text-align: right;
`;

const RemoveItem = styled('div')`
  grid-area: topright;
  text-align: right;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: none;
    background: lightgray;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 8px;
    width: 8px;
  }
`;

const cartImageStyles = css``;

const CartItem = ({ item, result, className = '' }) => {
  const customFields = item?.product?.customFields;
  const imageUrlsArray = customFields?.find(
    customField => customField.key === 'imageUrls'
  );

  const listValues = imageUrlsArray?.listValues?.[0];

  const { isVariant, variantValues } = getCartItemVariant(item);

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const { unBookProduct } = useProductBooking(item);

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  );

  return (
    <>
      <Wrapper className={className}>
        {listValues && (
          <InnerWrapper>
            <Image
              aspect="1:1"
              sizes="5rem"
              src={listValues}
              alt=""
              quality={80}
              fillAvailableSpace={true}
              className={cartImageStyles}
            />
          </InnerWrapper>
        )}
        <ProductDetail>
          <ItemDetails>
            <FlyoutTrigger id="cart-flyout">
              {flyout => (
                <ProductName onClick={flyout.hideTarget}>
                  <Link to={item.product.primaryRoute.path}>
                    <h2 data-testid="item-name">{item.product.name}</h2>
                    {isVariant && (
                      <ul
                        style={{
                          marginTop: '0'
                        }}
                      >
                        {variantNamesValues.map(
                          (variantOptionNameValue, index) => (
                            <li className="attribute" key={index}>
                              {variantOptionNameValue}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                    {item.configurations.length > 0 && (
                      <ul
                        style={{
                          marginTop: '0'
                        }}
                      >
                        {item.configurations.map(
                          ({ option: { name } }, index) => (
                            <li className="attribute" key={index}>
                              {name}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </Link>
                </ProductName>
              )}
            </FlyoutTrigger>
          </ItemDetails>

          <RemoveItem>
            <button
              onClick={() => {
                removeFromCart({ itemId: item.id, product: item.product });
                unBookProduct();
              }}
              data-testid="remove-from-cart"
            >
              <CrossIcon />
            </button>
          </RemoveItem>

          <LinePrice
            data-testid="item-price"
            price={item.total}
            previousPrice={item.previousTotal}
          />
        </ProductDetail>
      </Wrapper>
      <Divider />
    </>
  );
};

export default CartItem;
