module.exports = {
  default: {
    colors: {
      white: '#FFFFFF',
      black: '#000000',
      main: '#000000',
      grey: '#878787',
      darkerGrey: '#767676',
      lightgrey: '#E8E8E8',
      tablegrey: '#F3F3F3',
      beige: '#f5f5dc',
      loadingBar: '#2f80ed',
      blue: '#146DE1',
      red: '#EB0000',
      background: '#f7f7f7',
      darkgrey: '#333',
      mediumgrey: '#9a9a9a',
      highlight: '#2f80ed',

      abdGreen: '#6CC400',
      abdGreenHover: '#539700',
      abdDarkGrey: '#1A202D',
      abdLightGrey: '#F3F3F3',
      abdMediumGrey: '#F6F6F6',
      abdTextGrey: '#3E4C69',
      abdBadgeGrey: '#EBEBEB',
      abdRegGrey: 'rgba(0, 0, 0, 0.2)',
      adbAboveGrey: 'rgba(26, 32, 45, 0.4)'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints: {
      xs: '25rem', // 400px
      sm: '40rem', // 640px
      md: '50rem', // 800px
      slg: '55rem', // 1024px
      lg: '64rem', // 1024px
      xl: '80rem', // 1280px
      sxl: '90rem', // 1280px
      xxl: '105rem' // 1680px
    },
    fontSizeBase: 16,
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    gap: {
      none: 0,
      small: '8px',
      medium: '16px',
      large: '24px',
      xlarge: '32px'
    },
    fonts: {
      primary: 'Barlow'
    }
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black'
    }
  }
};
