import React from 'react';
import { useAddToCart } from '@jetshop/core/cart/useAddToCart';
import { FormikProvider } from 'formik';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import {
  addToCartError,
  useTrackProductAdd
} from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { uniqueId } from 'lodash';
import { getAddToCartOptimisticResponse } from '@jetshop/core/components/Mutation/cartMutationUtils';
import { useCartId } from '@jetshop/core/components/Cart/CartIdContext';

export function addToCartSuccess({
  onAddToCartSuccess,
  callbackOptions,
  cartId,
  setCartId
}) {
  return ({ data }) => {
    if (!cartId || data.addToCart.cart.id !== cartId) {
      data.addToCart && setCartId(data.addToCart.cart.id);
    }
    onAddToCartSuccess &&
      onAddToCartSuccess({
        ...callbackOptions,
        cartId:
          cartId || data.addToCart.cart.id === cartId
            ? cartId
            : data.addToCart.cart.id
      });
  };
}

export function AddToCartFormik({
  product,
  variant,
  commentValidators = {},
  getMissingOptions,
  initialValues,
  // Apollo specific props
  onAddToCartInit,
  onAddToCartError,
  onAddToCartSuccess,
  cartQuery,
  cartMutation,
  // Backwards compatability with render props
  children
}) {
  const client = useApolloClient();

  const [addToCartMutation] = useMutation(cartMutation, {
    onCompleted: () => localStorage.setItem('cartChange', '1')
  });

  const { cartId, setCartId } = useCartId();
  const trackAddProductVariant = useTrackProductAdd(variant);

  async function submit({ input, optimistic }) {
    trackAddProductVariant(product, input.quantity);
    const mutationId = uniqueId();

    const callbackOptions = {
      product: optimistic.product,
      price: optimistic.price,
      quantity: input.quantity,
      selectedVariation: variant,
      mutationId
    };

    if (onAddToCartInit) {
      onAddToCartInit(callbackOptions);
    }

    return addToCartMutation({
      variables: {
        input
      },
      optimisticResponse: {
        addToCart: {
          cart: getAddToCartOptimisticResponse(
            optimistic.product,
            input.quantity,
            cartId,
            client,
            cartQuery
          ),
          __typename: 'CartMutation'
        }
      }
    })
      .then(
        addToCartSuccess({
          onAddToCartSuccess,
          callbackOptions,
          cartId,
          setCartId
        })
      )
      .catch(
        addToCartError({
          onAddToCartError,
          callbackOptions
        })
      );
  }

  const { form } = useAddToCart({
    product,
    variant,
    commentValidators,
    getMissingOptions,
    initialValues,
    submit
  });

  return <FormikProvider value={form}>{children(form)}</FormikProvider>;
}

export default AddToCartFormik;
