import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const StoreName = styled('div')`
  svg {
    width: 150px;

    ${theme.below.md} {
      width: 100px;
    }
  }
`;

const Logo = ({ logo }) => (
  <>
    <StoreName>
      <Link to="/">
        <img src={logo} alt="logotype" />
      </Link>
    </StoreName>
  </>
);

export { Logo };
