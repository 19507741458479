import React, { useContext } from 'react';

import { useMutation } from '@apollo/react-hooks';

import t from '@jetshop/intl';
import { css } from 'linaria';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCartSuccess } from '../ProductPage/AddToCart/Formik/AddToCartFormik';
import { useNotification } from '@jetshop/core/components/Notifications';

import ProductToastWrapper from '../ProductPage/AddToCart/ProductToast';
import ButtonWithLoading from '../ui/Button';
import { addToCart } from '../Cart/queries/addToCart.gql';
import useReservedProduct from '../../hooks/useReservedProduct';
import { useProductValidationMessage } from '../ProductPage/useProductValidationMessage';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import cartQuery from '../Cart/queries/cartQuery.gql';
import { ReactComponent as AddToCarIcon } from '../../assets/svg/AddToCart.svg';
import useProductBooking from '../ProductPage/useProductBooking';

const addToCartIcon = css`
  margin-left: 10px;
`;

/**
 *
 * @param {object} props
 * @param {import('@jetshop/core/hooks/ProductList').ProductListProduct} props.product
 */
export function AddToCart({ product }) {
  const { cartId, setCartId } = useContext(CartIdContext);
  const articleNumber =
    product?.variant?.articleNumber || product.articleNumber;
  const price = {
    price: product?.variant?.price || product.price,
    previousPrice: product?.variant?.previousPrice || product.previousPrice
  };

  const variantHandler = useProductVariants(product);

  const { validation: variantValidation, missingOptions } = variantHandler;
  const { hasVariants } = product;
  const reserved = useReservedProduct(product);

  const stockStatus = useStockStatus(product);

  const {
    isBooked,
    updateProductStatus,
    bookProduct,
    loading
  } = useProductBooking(product);

  const { validationMessage } = useProductValidationMessage({
    missingOptions,
    variantValidation,
    hasVariants,
    stockStatus,
    reserved,
    isBooked
  });

  const [trigger] = useNotification();

  const onAddToCartSuccess = ({ cartId }) => bookProduct(cartId);
  const [add] = useMutation(addToCart, {
    variables: {
      input: {
        cartId,
        articleNumber,
        quantity: 1
      }
    },
    onCompleted: data => {
      trigger(
        <ProductToastWrapper
          selectedVariation={product.variant}
          product={product}
          quantity={1}
          price={price}
        />,
        { type: 'add-to-cart' }
      );

      addToCartSuccess({ cartId, setCartId, onAddToCartSuccess })({ data });
    }
  });

  const handleAddToCart = async () => {
    const { bookingError } = await updateProductStatus();
    if (bookingError) return null;
    return add();
  };

  return (
    <CartProvider query={cartQuery}>
      {result => {
        const inCart = result?.data?.cart?.items?.find(
          item => item.articleNumber === articleNumber
        );
        return (
          <ButtonWithLoading
            fillWidth
            fontSize={16}
            disabled={!!validationMessage || !!inCart}
            onClick={handleAddToCart}
            loading={loading}
            loadingText="Bokar produkt..."
          >
            {inCart
              ? t('Bokad i varukorgen')
              : validationMessage
              ? validationMessage
              : t('Add to cart')}
            {!validationMessage && !inCart && (
              <AddToCarIcon className={addToCartIcon} />
            )}
          </ButtonWithLoading>
        );
      }}
    </CartProvider>
  );
}
