import { useIntl } from '@jetshop/intl';
import { useEffect, useReducer, useState } from 'react';

export function useProductValidationMessage({
  missingOptions,
  variantValidation,
  hasVariants,
  stockStatus,
  isBooked,
  reserved
}) {
  const t = useIntl();

  // Track submits, so we can enable after the first one

  const [submitCount, enableValidation] = useReducer(count => count + 1, 0);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    if (submitCount > 0) {
      if (missingOptions?.length > 0) {
        setValidationMessage(`Select { option }`, {
          option: missingOptions[0].name
        });
      }
    }

    if (
      variantValidation === 'outOfStock' ||
      (!hasVariants && stockStatus.status === 'outOfStock')
    ) {
      setValidationMessage(t('Out of stock'));
    } else {
      setValidationMessage('');
    }

    if (isBooked || reserved) {
      setValidationMessage(t('Booked'));
    }
  }, [
    submitCount,
    missingOptions,
    isBooked,
    hasVariants,
    stockStatus,
    variantValidation,
    validationMessage,
    reserved,
    t
  ]);

  return {
    enableValidation,
    validationMessage
  };
}
