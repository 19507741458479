import { useCustomFields } from './useCustomFields';

const useReservedProduct = product => {
  const { reservedQuantity } = useCustomFields(product);

  const reserved = reservedQuantity?.value > 0;

  return reserved;
};

export default useReservedProduct;
