import { css } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import Button from '@jetshop/ui/Button';

const wrapperStyling = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: ${theme.fonts.primary};
  font-size: 1rem;
  position: relative;
  border: 1px solid #ebebeb;
  background: ${theme.colors.white};
  padding: 0rem 0.5rem 0.5rem;
  height: 100%;

  a {
    text-decoration: none;
    color: inherit;
    display: block;
  }

  .package-badge {
    background: black;
    color: ${theme.colors.white};
    font-size: 14px;
    padding: 3px 6px;
  }

  .price-package-wrapper {
    display: inline-block;
    display: flex;
    justify-content: flex-end;
    margin: 0 -1rem 0rem;
    padding: 0rem 1rem;

    .package-price-label {
      font-size: 12px;
      color: ${theme.colors.darkerGrey};
      margin-left: 5px;
    }
  }
`;

const ImageWrapper = styled.div`
  aspect-ratio: 320 / 212;

  [data-flight-image-container] {
    height: 100% !important;
    padding-bottom: 0 !important;
  }
`;

const ProductTitle = styled.h2`
  font-size: 1.125rem;
  padding-top: 1rem;
`;

const TopBar = styled.div`
  padding: 0.2rem 0;
  display: flex;
  justify-content: space-between;
`;

const SpecificationDetailContainer = styled('div')`
  font-size: ${props => (props.size === 'large' ? '1rem' : '1rem')};
  font-weight: ${props => (props.size === 'large' ? '400' : '400')};
`;

const SpecificationTitle = styled('span')`
  margin-right: 5px;
`;

const SpecificationValue = styled('span')`
  color: ${props => (props.size === 'large' ? theme.colors.black : 'inherit')};
  font-weight: ${props => (props.size !== 'large' ? '700' : '600')};
`;

const ModalButton = styled(Button)`
  display: inline-block;
  padding: 0;
  height: auto;
  width: auto;
  background-color: transparent;
  margin-left: 10px;
  transform: translateY(-2px);
  opacity: 0.5;
`;

const style = {
  wrapperStyling,
  ImageWrapper,
  ProductTitle,
  TopBar,
  SpecificationDetailContainer,
  SpecificationTitle,
  SpecificationValue,
  ModalButton
};

export default style;
