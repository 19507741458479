import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import t from '@jetshop/intl';
import { Currency } from '@jetshop/ui/Price/Currency';

const ProductCardBadgeWrapper = styled('div')`
  background-color: ${theme.colors.abdBadgeGrey};
  color: ${theme.colors.abdDarkGrey};
  display: inline-block;
  font-weight: 700;
  font-size: 12px;
  padding: 5px 10px;
`;

const Badge = styled('div')``;

const CurrencyWrapper = styled('span')`
  margin-left: 3px;
`;

const ProductCardBadge = ({ price, previousPrice }) => {
  let newPrice = price?.incVat;
  let previous = previousPrice?.incVat;
  let savings = (previous -= newPrice);

  return (
    <ProductCardBadgeWrapper>
      <Badge>
        {t('Save')}
        <CurrencyWrapper>
          <Currency value={savings} />
        </CurrencyWrapper>
      </Badge>
    </ProductCardBadgeWrapper>
  );
};

export default ProductCardBadge;
