import { useMemo } from 'react';
import useTranslatableCustomFieldTitles from '../components/ProductPage/useTranslatableCustomFieldTitles';

const normalizeKey = key => {
  const lowerCaseFirstLetter = key.replace(/(^.)(..)/, (match, _, string) => {
    return string === string.toUpperCase() ? match : match.toLowerCase();
  });
  const upperCaseFirstAfterDash = lowerCaseFirstLetter.replace(
    /-(.)/g,
    (_, selectedMatch) => {
      return selectedMatch.toUpperCase();
    }
  );
  const removeUnderscore = upperCaseFirstAfterDash.replace(/_|-/g, '');

  return removeUnderscore;
};

const getFields = customFields => {
  if (!customFields) return {};

  const arr = customFields.reduce(
    (
      accFields,
      {
        key,
        type,
        title,
        boolValue,
        stringValue,
        htmlValue,
        listValues,
        ...rest
      }
    ) => {
      if (!key) return accFields;
      const normalizedKey = normalizeKey(key);
      switch (type) {
        case 'BOOL':
          return {
            ...accFields,
            [normalizedKey]: {
              type,
              value: boolValue,
              title,
              boolValue,
              ...rest
            }
          };

        case 'STRING':
          return {
            ...accFields,
            [normalizedKey]: {
              type,
              value: stringValue,
              title,
              stringValue,
              ...rest
            }
          };

        case 'HTML':
          return {
            ...accFields,
            [normalizedKey]: {
              type,
              value: htmlValue,
              title,
              htmlValue,
              ...rest
            }
          };

        case 'LIST':
          return {
            ...accFields,
            [normalizedKey]: {
              type,
              value: listValues,
              title,
              listValues,
              ...rest
            }
          };
        case 'MULTI_LEVEL_LIST':
          return {
            ...accFields,
            [normalizedKey]: {
              type,
              title,
              ...rest
            }
          };

        default:
          return accFields;
      }
    },
    {}
  );

  return arr;
};

export const useCustomFields = ({ customFields }) => {
  const translateableCustomFields = useTranslatableCustomFieldTitles(
    customFields
  );
  return useMemo(() => {
    if (!translateableCustomFields?.length > 0) return {};

    return getFields(translateableCustomFields);
  }, [translateableCustomFields]);
};

export const useCustomFieldsMultipleProducts = products => {
  return useMemo(() => {
    if (!products?.length > 0) return {};

    return products.map(product => ({
      ...product,
      ...getFields(product)
    }));
  }, [products]);
};
