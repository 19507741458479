import React from 'react';
import { getArticleNumber } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { useCartId } from '@jetshop/core/components/Cart/CartIdContext';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import cartQuery from '../Cart/queries/cartQuery.gql';
import removeFromCartMutation from '../Cart/queries/removeFromCart.gql';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { useNotification } from '@jetshop/core/components/Notifications';
import BookProduct from './BookProduct.gql';
import UnBookProduct from './UnBookProduct.gql';
import ProductStatus from './ProductStatus.gql';
import { useEffect, useState } from 'react';
import { useCustomFields } from '../../hooks/useCustomFields';
import t from '@jetshop/intl';

const getTranslatedMessage = message => {
  switch (message) {
    case 'Item not found':
      return t('Item not found');
    case 'Product is already booked':
    default:
      return t('Product is already booked');
  }
};

const useProductBooking = (product, variant) => {
  const { reserved } = useCustomFields(product);
  const [isBooked, setIsBooked] = useState(!!reserved);
  const [errorMessage, setErrorMessage] = useState('');
  const [bookingStatusLoading, setBookingStatusLoading] = useState(false);
  const productId = getArticleNumber(product, variant);
  const { cartId } = useCartId();
  const [trigger] = useNotification();
  const client = useApolloClient();
  const [bookProductMutation, { loading: bookingLoading }] = useMutation(
    BookProduct
  );
  const [unBookProductMutation, { loading: unbookingLoading }] = useMutation(
    UnBookProduct
  );

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const updateProductStatus = async () => {
    setBookingStatusLoading(true);
    const { data } = await client.query({
      query: ProductStatus,
      variables: { productId }
    });
    setBookingStatusLoading(false);

    const bookingError = data?.ProductStatus?.bookingStatus === 'BOOKED';
    setIsBooked(bookingError);
    setErrorMessage(bookingError);

    return {
      bookingError
    };
  };

  const bookProduct = async _cartId => {
    const { loading: bookingLoading, data } = await bookProductMutation({
      variables: { productId, externalReservationId: _cartId }
    });

    const bookingError = data?.productBook?.message;
    setErrorMessage(bookingError);
    setIsBooked(data?.productBook?.status === 'BOOKED');

    if (bookingError) {
      const { data } = await client.query({
        query: cartQuery,
        variables: { cartId: _cartId }
      });
      const { id } = data?.cart?.items?.find(
        item => item.articleNumber === productId
      );
      removeFromCart({
        itemId: id,
        product
      });
    }

    return {
      bookingLoading,
      bookingError
    };
  };

  const unBookProduct = async () => {
    const {
      loading: bookingLoading,
      data,
      error
    } = await unBookProductMutation({
      variables: { productId, externalReservationId: cartId }
    });
    const bookingError =
      error ||
      (!data?.bookProduct?.success &&
        data?.bookProduct?.status !== 'AVAILABLE');

    setIsBooked(!bookingError);

    return {
      bookingLoading,
      bookingError
    };
  };

  useEffect(() => {
    if (errorMessage) {
      return trigger(
        <div>{getTranslatedMessage(errorMessage)}</div>,

        {
          id: 'product-status-booked',
          type: 'product-status-booked',
          autoCloseAfter: 0
        }
      );
    }
  }, [errorMessage, trigger]);

  return {
    bookProduct,
    unBookProduct,
    updateProductStatus,
    isBooked,
    loading: bookingLoading || unbookingLoading || bookingStatusLoading
  };
};

export default useProductBooking;
