import React from 'react';
import QualityWarrantyQuery from '../ProductPage/QualityWarrantyQuery.gql';
import { useQuery } from '@apollo/react-hooks';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import QualityWarrantyData from './QualityWarrantyData';
import ButtonWithLoading from '../ui/Button';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { css } from 'linaria';

const CloseModalButton = styled(ButtonWithLoading)`
  padding: 8px 30px;
  margin-top: 1rem;
`;

const closeIcon = css`
  cursor: pointer;
  font-size: 2rem;
  text-align: right;
  padding: 10px;
  line-height: 0; /* Set line-height to 0 */
`;

const QualityWarantyInfo = ({ hideTarget }) => {
  const qualityWarrantyId = process.env.REACT_APP_PRODUCT_PAGE_ID;

  const { data } = useQuery(QualityWarrantyQuery, {
    variables: { qualityWarrantyId: Number(qualityWarrantyId) }
  });

  const items = data?.category?.data?.items ?? [];

  const qualityWarrantyComponents = {
    QUALITYWARRANTYINFORMATIONBLOCK: QualityWarrantyData
  };

  return (
    <div>
      <p className={closeIcon} onClick={hideTarget}>
        x
      </p>
      <ContentRenderer items={items} components={qualityWarrantyComponents} />
      <CloseModalButton onClick={hideTarget}>{t('Close')}</CloseModalButton>
    </div>
  );
};

export default QualityWarantyInfo;
