import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import t from '@jetshop/intl';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { ReactComponent as Cart } from '../../assets/svg/Cart.svg';
import Badge from '../ui/Badge';
import cartQuery from './queries/cartQuery.gql';

const InnerWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const ButtonCart = styled('button')`
  position: relative;
  padding: 0;
  background: transparent;
  color: white !important;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  flex-direction: column;
  cursor: pointer;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 13px 20px 14px 20px;
  right: -0.625rem;

  svg {
    width: 24px;
    height: 24px;
    margin: 0px;
    use {
      fill: #878787;
    }
  }

  svg:hover {
    path {
      fill: ${theme.colors.abdGreen} !important;
    }
  }

  ${theme.above.lg} {
    right: 0;
    background-color: #6cc400 !important;
    color: white !important;
  }
`;

function CartButton() {
  return (
    <CartProvider query={cartQuery}>
      {result => {
        const itemsInCart = result?.data?.cart?.totalQuantity || 0;

        // Drawer on mobile and tablet, flyout on desktop
        return (
          <Above breakpoint="lg">
            {matches =>
              matches ? (
                <FlyoutTrigger id="cart-flyout">
                  {flyout => (
                    <ButtonCart
                      data-testid="cart-button"
                      onClick={
                        flyout.isOpen ? flyout.hideTarget : flyout.showTarget
                      }
                    >
                      <InnerWrapper>
                        <span>{t('To the checkout')}</span>
                        {itemsInCart > 0 && (
                          <Badge
                            text={itemsInCart}
                            positions={{ top: '6px', right: '6px' }}
                            desktopBg
                          />
                        )}
                      </InnerWrapper>
                    </ButtonCart>
                  )}
                </FlyoutTrigger>
              ) : (
                <DrawerTrigger preventOverflow={true} id="cart-drawer">
                  {drawer => (
                    <ButtonCart
                      data-testid="cart-button"
                      onClick={
                        drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                      }
                    >
                      <Cart />
                      {itemsInCart > 0 && (
                        <Badge
                          text={itemsInCart}
                          positions={{ top: '6px', right: '6px' }}
                        />
                      )}
                    </ButtonCart>
                  )}
                </DrawerTrigger>
              )
            }
          </Above>
        );
      }}
    </CartProvider>
  );
}

export default CartButton;
