import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import { LoadableWindowedCategoryPage } from './components/CategoryPage/CategoryPage';
import { theme } from './components/Theme';

import ProductPage from './components/ProductPage/ProductPage.loadable';
import ContentPage from './components/ContentPage/ContentPage.loadable';

const config = {
  theme,
  apolloConfig: {
    useGraphQLProxyServer: true,
    shopid: process.env.REACT_APP_SHOP_ID || 'allabildelar',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI ||
      'https://allabildelar.storeapi.jetshop.io/',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 }
      }
    ]
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  additionalGtagTrackingIds: [],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {}
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode:
    typeof process.env.REACT_APP_SINGLE_DOMAIN_MODE === 'undefined'
      ? false
      : process.env.REACT_APP_SINGLE_DOMAIN_MODE, // default to true if this env var is not set
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  openGraph: {
    disableDefaultProductData: false
  },
  trackers: [],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [ProductPage],
      Category: [LoadableWindowedCategoryPage],
      Page: [ContentPage]
    }
  },
  usePrimaryRouteForProducts: false
};
export default config;
