import Accordion from '@jetshop/ui/Accordion/Accordion';
import Link from '@jetshop/ui/CategoryLink';
import { ReactComponent as ChevronRight } from '../../../../svg/Caret.svg';
import React, { useCallback } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

const CategoryTree = styled('ul')`
  display: flex;
  flex-direction: column;
  list-style: none;
  flex: 1 0 auto;

  a,
  a:hover {
    text-decoration: none;
  }
`;
const SubCategories = styled(CategoryTree)`
  padding-left: 1rem;
`;

const CategoryLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  position: relative;
  padding: 0.5rem 0;

  svg {
    display: none;
    margin-right: 0px;
    transition: transform 150ms ease-in;
    transform: rotate(-90deg);
  }

  &.with-chevron {
    svg {
      display: block;
    }
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const SubCategoryLink = styled(CategoryLink)`
  padding-left: 2em;
`;

const CategoryName = styled('h2')`
  font-size: 16px;
  font-weight: normal;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const SubCategory = styled('li')`
  font-size: 0.875rem;
`;

const RootCategory = styled('li')`
  display: flex;
  flex-direction: column;
`;

const DrawerMenu = ({ categories, closeMenu }) => {
  const renderLevel2Items = useCallback(
    category => {
      const { subcategories } = category;
      if (!subcategories || !subcategories.length) return null;

      return (
        <>
          <SubCategories>
            <SubCategory>
              <CategoryLink
                exact
                category={category}
                onClick={() => closeMenu()}
              >
                {t('View all in {category}', {
                  category: category.name.toLowerCase()
                })}
              </CategoryLink>
            </SubCategory>

            <Accordion single>
              {({ openIndexes, handleClick, AccordionContent }) =>
                subcategories.map((cat, index) => {
                  let classNames = [];
                  // Give categories with children a chevron
                  cat.subcategories?.length && classNames.push('with-chevron');
                  // Add 'open' class when accordion is opened
                  const open = openIndexes.includes(index);
                  open && classNames.push('open');

                  return (
                    <React.Fragment key={cat.id}>
                      <SubCategory>
                        <CategoryLink
                          category={cat}
                          onClick={e =>
                            handleClickWrapper(
                              e,
                              cat.subcategories?.length,
                              index,
                              handleClick
                            )
                          }
                          className={
                            classNames.length > 0 ? classNames.join(' ') : null
                          }
                        >
                          {cat?.name}
                          <ChevronRight />
                        </CategoryLink>
                      </SubCategory>
                      <AccordionContent isOpen={open}>
                        <SubCategory>
                          <SubCategoryLink
                            exact
                            category={cat}
                            onClick={() => closeMenu()}
                          >
                            {t('View all in {category}', {
                              category: cat.name
                            })}
                          </SubCategoryLink>
                        </SubCategory>
                        {renderLevel3Items(cat)}
                      </AccordionContent>
                    </React.Fragment>
                  );
                })
              }
            </Accordion>
          </SubCategories>
        </>
      );
    },
    [closeMenu]
  );

  const renderLevel3Items = useCallback(
    category => {
      const { subcategories } = category;
      if (!subcategories || !subcategories.length) return null;

      return subcategories.map(cat => (
        <SubCategory key={cat.id}>
          <SubCategoryLink category={cat} onClick={() => closeMenu()} />
        </SubCategory>
      ));
    },
    [closeMenu]
  );

  const handleClickWrapper = useCallback(
    (e, hasChildren, index, handleAccordionClick) => {
      if (hasChildren) {
        e.stopPropagation();
        e.preventDefault();
      } else {
        closeMenu();
      }
      handleAccordionClick(index);
    },
    [closeMenu]
  );

  if (categories && categories.length > 0) {
    return (
      <Accordion single>
        {({ openIndexes, handleClick, AccordionContent }) =>
          categories.map((cat, index) => {
            let classNames = [];
            // Give categories with children a chevron
            cat.subcategories.length && classNames.push('with-chevron');
            // Add 'open' class when accordion is opened
            const open = openIndexes.includes(index);
            open && classNames.push('open');

            return (
              <RootCategory key={cat.id}>
                <CategoryName>
                  <CategoryLink
                    category={cat}
                    onClick={e =>
                      handleClickWrapper(
                        e,
                        cat.subcategories.length,
                        index,
                        handleClick
                      )
                    }
                    className={
                      classNames.length > 0 ? classNames.join(' ') : null
                    }
                  >
                    {cat?.name}
                    <ChevronRight />
                  </CategoryLink>
                </CategoryName>
                <AccordionContent isOpen={open}>
                  {renderLevel2Items(cat)}
                </AccordionContent>
              </RootCategory>
            );
          })
        }
      </Accordion>
    );
  } else return null;
};

export default DrawerMenu;
